



























































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DataTable from "@/components/data-table/DataTable.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import { ITableHeader } from "@/components/data-table/types";
import BuyersSelectedActions from "@/components/project-buyers/BuyersSelectedActions.vue";
import { LinkType } from "@/enums";
import Contact from "@/entity/Contact";
import { ReferenceItem } from "@/services/types";
import { get } from "lodash";
import Link from "@/components/common/Link.vue";
import contactDetailNavigationTo from "@/utils/contactDetailNavigateTo";
import companyDetailNavigationTo from "@/utils/companyDetailNavigateTo";
import BuyerAnalyticsDataTableToolbar from "@/components/buyer-analytics/BuyerAnalyticsDataTableToolbar.vue";
import { TableQuery } from "@/components/data-table/types";
import ProjectValuationBuyersDataTableToolbar from "@/components/project-valuation-buyers/ProjectValuationBuyersDataTableToolbar.vue";
import { uniqBy } from "lodash";
import { BuyerStatusForBuyerAnalytics } from "@/entity/ProjectBuyer";

@Component({
  components: {
    ProjectValuationBuyersDataTableToolbar,
    DataTable,
    ActionButtonWithTooltip,
    BuyersSelectedActions,
    Link,
    BuyerAnalyticsDataTableToolbar,
  },
})
export default class BuyerAnalyticsDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @PropSync("selectedContacts", { type: Array, required: true })
  selectedContactsLocal!: Contact[];

  @Prop({ required: true, type: Array }) contacts!: Contact[];
  @Prop({ required: true, type: Array }) buyerTypes!: ReferenceItem[];
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) openExportDialog!: () => void;

  initialTableOptions: TableQuery = {
    page: 1,
    itemsPerPage: 10,
  };
  contactDetailLink = contactDetailNavigationTo;
  get = get;
  LinkType = LinkType;
  companyDetailLink = companyDetailNavigationTo;

  resetSelection() {
    this.selectedContactsLocal = [];
  }

  isRowSelected(id: number): boolean {
    return (
      this.selectedContactsLocal.findIndex((contact) => contact.id === id) > -1
    );
  }

  rowSelection(item: Contact): void {
    const existingIndex = this.selectedContactsLocal.findIndex(
      (contact) => contact.id === item.id
    );

    if (existingIndex > -1) {
      this.selectedContactsLocal.splice(existingIndex, 1);
    } else {
      this.selectedContactsLocal.push(item);
    }
  }

  get headers(): ITableHeader[] {
    return [
      {
        text: this.$tc("select", 1),
        value: "select",
        width: "60px",
        filterType: "selectAll",
        sortable: false,
      },
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "lastName",
        width: "200px",
        filterValue: "firstName", // First name and last name are switched in DB
        sortable: false,
      },
      {
        filterType: "text",
        text: this.$tc("firstName", 1),
        value: "firstName",
        width: "200px",
        filterValue: "lastName", // First name and last name are switched in DB
        sortable: false,
      },
      {
        filterType: "text",
        text: this.$tc("city", 1),
        value: "address.city",
        width: "150px",
        filterValue: "city",
        sortable: false,
      },
      {
        filterType: "text",
        text: this.$tc("email", 1),
        value: "email",
        width: "200px",
        filterValue: "mail",
        sortable: false,
      },
      {
        text: this.$tc("companyName", 1),
        value: "company",
        filterType: "text",
        width: "200px",
        sortable: false,
      },
      {
        sortable: false,
        text: this.$tc("phoneMobile", 1),
        value: "phoneMobile",
        width: "150px",
      },
      {
        text: this.$tc("buyerType", 1),
        value: "buyerType.name",
        filterValue: "buyerTypeId",
        sortable: true,
        options: this.buyerTypes,
        optionText: "name",
        optionValue: "id",
        filterType: "select",
        width: "150px",
      },
      ...BuyerStatusForBuyerAnalytics.map((status) => ({
        text: this.$tc(status),
        value: `buyerAnalyticsStats.${status.split(".")[1]}`,
        sortable: true,
        filterable: false,
        width: "150px",
      })),
      {
        text: this.$tc("actions"),
        value: "action",
        width: "80px",
        sortable: false,
      },
    ];
  }

  handleSelectAll(): void {
    this.selectedContactsLocal = uniqBy(
      this.contacts.concat(this.selectedContactsLocal),
      (contact) => {
        return contact.id;
      }
    );
  }

  deleteAll() {
    this.deleteContact(
      this.selectedContactsLocal.map((contact) => contact.id as number)
    );
  }

  deleteContact(contactId: number | number[]): void {
    const idToEmit = Array.isArray(contactId) ? contactId : [contactId];

    this.$emit("delete", idToEmit);
  }
}
