export const DEFAULT_MONEY_RANGE_VALUES = [
  100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
  1000000, 2000000, 3000000, 4000000, 5000000, 10000000, 20000000, 30000000,
  40000000, 50000000,
] as number[];

export const BUYER_ANALYTICS_MONEY_RANGE_VALUES = [
  0, 100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
  1000000, 2000000, 3000000, 4000000, 5000000, 10000000, 20000000, 30000000,
  40000000, 50000000,
] as number[];

export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm";
export const CURRENCY = "CHF";
export const SNACKBAR_TIMEOUT = 2000;
export const ALL_ITEMS_PER_PAGE = 1000000;
export const DEFAULT_INITIAL_PAGE = 1;
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [5, 10, 15, -1];

export const MAIN_MATERIAL_COLOR_LIST = [
  "#002D8C",
  "#4C6CAE",
  "#99ABD1",
  "#E5EAF3",
  "#2FAE80",
  "#3BDAA1",
  "#9DECD0",
  "#74787C",
  "#515456",
  "#ABAEB0",
  "#E3E4E4",
  "#FFFFFF",
];

export const EXCEL_FILE_MIME_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const PROJECT_FINANCE_PERCENTAGE_INDICATOR = [
  "Bruttomarge",
  "Bruttomarge II",
  "EBITDA Marge",
  "EBIT Marge",
  "Liquiditätsgrad 1",
  "Liquiditätsgrad 2",
  "Liquiditätsgrad 3",
  "Anlagedeckungsgrad 2",
  "Eigenkapitalrendite (ROE)",
  "Umsatzrendite",
  "Verschuldungsgrad",
  "Nettoverschuldungsquote (ND to EBITDA)",
];

export const alphabet = "abcdefghijklmnopqrstuvwxyz";

// ! changing the values here or changing the names of the buyer types in DB
// ! may break the `ProjectValuationByerTypeTiles.vue` component in valuation
// ! report, page 13...
export enum BUYER_TYPE_DB_NAMES {
  privateBuyer = "Privater Käufer",
  strategicBuyer = "Strategischer Käufer",
  financialInvestor = "Finanzinvestor",
  noSearchProfile = "Kein Käuferprofil",
}
